require('../css/main.scss');
require('../../node_modules/owl.carousel');
require("../../node_modules/jquery-mousewheel")
require('../../node_modules/malihu-custom-scrollbar-plugin');

document.querySelectorAll('.drop-zone__input').forEach((inputElement) => {
  const dropZoneElement = inputElement.closest('.drop-zone');

  dropZoneElement.addEventListener('click', (e) => {
    inputElement.click();
  });

  inputElement.addEventListener('change', (e) => {
    if (inputElement.files.length) {
      updateThumbnail(dropZoneElement, inputElement.files[0]);
    }
  });

  dropZoneElement.addEventListener('dragover', (e) => {
    e.preventDefault();
    dropZoneElement.classList.add('drop-zone--over');
  });

  ['dragleave', 'dragend'].forEach((type) => {
    dropZoneElement.addEventListener(type, (e) => {
      dropZoneElement.classList.remove('drop-zone--over');
    });
  });

  dropZoneElement.addEventListener('drop', (e) => {
    e.preventDefault();

    if (e.dataTransfer.files.length) {
      inputElement.files = e.dataTransfer.files;
      updateThumbnail(dropZoneElement, e.dataTransfer.files[0]);
    }

    dropZoneElement.classList.remove('drop-zone--over');
  });
});

/**
 * Updates the thumbnail on a drop zone element.
 *
 * @param {HTMLElement} dropZoneElement
 * @param {File} file
 */
function updateThumbnail(dropZoneElement, file) {
  let thumbnailElement = dropZoneElement.querySelector('.drop-zone__thumb');

  // First time - remove the prompt
  if (dropZoneElement.querySelector('.drop-zone__prompt')) {
    dropZoneElement.querySelector('.drop-zone__prompt').remove();
  }

  // First time - there is no thumbnail element, so lets create it
  if (!thumbnailElement) {
    thumbnailElement = document.createElement('div');
    thumbnailElement.classList.add('drop-zone__thumb');
    dropZoneElement.appendChild(thumbnailElement);
  }

  thumbnailElement.dataset.label = file.name;

  // Show thumbnail for image files
  // if (file.type.startsWith("image/")) {
  //   const reader = new FileReader();
  //
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
  //   };
  // } else {
  //   thumbnailElement.style.backgroundImage = null;
  // }
}


document.addEventListener("DOMContentLoaded", function(event) {

    if ($('.slider-content').length) {
        const slides = document.querySelectorAll('.slider-container ul li');
        const animationTimeout = 50;
        let currentSlideIndex = 0;

        function moveLeft() {
            if (currentSlideIndex > 0) {
                slides[currentSlideIndex].classList.add('hidden');
                slides[currentSlideIndex].addEventListener('transitionend', function (e) {
                    slides[currentSlideIndex].classList.add('visually-hidden');
                }, {
                    capture: false,
                    once: true,
                    passive: false
                });
                currentSlideIndex--;
                slides[currentSlideIndex].classList.remove('hidden');
                setTimeout(function () {
                    slides[currentSlideIndex].classList.remove('visually-hidden');
                }, animationTimeout);
            } else {
                slides[currentSlideIndex].classList.add('hidden');
                slides[currentSlideIndex].addEventListener('transitionend', function (e) {
                    slides[currentSlideIndex].classList.add('visually-hidden');
                }, {
                    capture: false,
                    once: true,
                    passive: false
                });
                currentSlideIndex = slides.length - 1;
                slides[currentSlideIndex].classList.remove('hidden');
                setTimeout(function () {
                    slides[currentSlideIndex].classList.remove('visually-hidden');
                }, animationTimeout);
            }
        }

        function moveRight() {
            if (currentSlideIndex < slides.length - 1) {
                slides[currentSlideIndex].classList.add('hidden');
                slides[currentSlideIndex].addEventListener('transitionend', function (e) {
                    slides[currentSlideIndex].classList.add('visually-hidden');
                }, {
                    capture: false,
                    once: true,
                    passive: false
                });
                currentSlideIndex++;
                slides[currentSlideIndex].classList.remove('hidden');
                setTimeout(function () {
                    slides[currentSlideIndex].classList.remove('visually-hidden');
                }, animationTimeout);
            } else {
                slides[currentSlideIndex].classList.add('hidden');
                slides[currentSlideIndex].addEventListener('transitionend', function (e) {
                    slides[currentSlideIndex].classList.add('visually-hidden');
                }, {
                    capture: false,
                    once: true,
                    passive: false
                });
                currentSlideIndex = 0;
                slides[currentSlideIndex].classList.remove('hidden');
                setTimeout(function () {
                    slides[currentSlideIndex].classList.remove('visually-hidden');
                }, animationTimeout);
            }
        }

        const leftButton = document.querySelector('.slider-toggles--item__left');
        if (leftButton) {
            leftButton.addEventListener('click', moveLeft, false);
        }

        const rightButton = document.querySelector('.slider-toggles--item__right');
        if (rightButton) {
            rightButton.addEventListener('click', moveRight, false);
        }

        setInterval(moveRight, 5000);
    }



    // admin panel add remove row start
        $('.js-add-related').click(function(){
            let clone = $(this).closest('.js-related-wrapper').clone();
            clone.find('.js-remove-related').css({'display':'inline-block'});
            clone.find('input').val('');
            clone.find('.js-add-related').remove();
            clone.insertAfter($(this).closest('.js-related-wrapper'));
        });

        $(document).on('click', '.js-remove-related', function(){
            $(this).closest('.js-related-wrapper').remove();
        });
    // admin panel add remove row end

    // dropdown box start
        $('.js-dropdown-box-btn').click(function(){
            let btn   = $(this);
            let box   = btn.closest('.js-dropdown-box');
            let info  = box.find('.js-dropdown-box-info');
            let inner = box.find('.js-dropdown-box-inner');

            btn.toggleClass('active');
            if (btn.hasClass('active')) {
                info.slideUp('slow');
                inner.slideDown('slow');
            }
            else {
                info.slideDown('slow');
                inner.slideUp('slow');
            }
        })
    // dropdown box end

    // sliders start
        $('.js-about-carousel').owlCarousel({
            loop:true,
            margin:0,
            nav:false,
            items:1,
            autoplay:true,
            autoplayTimeout:5000,
            autoplayHoverPause:true
        })
        $('.js-about-galery-carousel').owlCarousel({
            loop:true,
            margin:20,
            nav:true,
            items:1,
            dots: false,
            navText: ['<span class="slider-prev-btn"><img src="/images/angel_bracket_left_black.svg"></span>','<span class="slider-next-btn"><img src="/images/angel_bracket_right_black.svg"></span>'],
            responsive : {
                578 : {
                    items : 3,
                },
                480 : {
                    items : 2,
                },
            }
        })
    // sliders end

    // spteam slider start
        var spTeamSlider = $('.js-spteam-carousel');
        spTeamSlider.owlCarousel({
            loop:true,
            margin:0,
            nav:true,
            items:1,
            dots: false,
            navText: ['<span class="slider-prev-btn"><img src="/images/angel_bracket_left_black.svg"></span>','<span class="slider-next-btn"><img src="/images/angel_bracket_right_black.svg"></span>'],
            responsive : {
                1360 : {
                    items : 3,
                },
            }
        })
        checkClasses();
        spTeamSlider.on('translated.owl.carousel', function(event) {
            checkClasses();
        });

        function checkClasses(){
            var total = $('.js-spteam-carousel .owl-stage .owl-item.active').length;
            $('.js-spteam-carousel .owl-stage .owl-item').removeClass('centerActiveItem');

            $('.js-spteam-carousel .owl-stage .owl-item.active').each(function(index){
                if (index === 1) {
                    $(this).addClass('centerActiveItem');
                }
            });
        }
    // spteam slider end

    // modal start
        $(document).on('click', '.js-modal-btn', function(){
            $('.js-modal-box.'+$(this).data('modalbox')).fadeIn('slow');
        });
        $(document).on('click', '.js-modal-box', function(event){
            $(this).fadeOut('slow');
        });
        $(document).on('click', '.js-modal-box-close', function(event){
            $(this).closest('.js-modal-box').fadeOut('slow');
        });
        $(document).on('click', '.js-modal-box-block', function(event){
            if ($(this).attr('id') == 'js-modal-box-catalog-cart-add-block') {
                $(this).css({'height':'auto'});
            }
            event.stopPropogation();
        });

        $(document).on('click', '.js-supplier-btn', function(){
            $('.js-supplier-input-title').val($(this).closest('.js-supplier').find('.js-supplier-title').text());
        });

        function showModalInfo(text){
            let modal = $('.js-modal-box.js-modal-box-info');
            modal.find('.js-modal-box-info-text').text(text);
            modal.fadeIn('slow');
        }

        $('.js-form-ajax-submit').submit(function(event){
            event.preventDefault();
            let form  = $(this);
            let modal = form.closest('.js-modal-box');

            form.find('button[type=submit]').attr("disabled", true);
            $.ajax({
                url : form.attr('action'),
                type: 'POST',
                data: form.serialize(),
                success : function(data){
                    if (data.success) {
                        form[0].reset();
                        if (modal.length) {
                            modal.fadeOut('fast', function(){
                                showModalInfo(form.data('successtext'));
                            });
                        }
                        else {
                            showModalInfo(form.data('successtext'));
                        }
                    }
                    form.find('button[type=submit]').attr("disabled", false);
                }
            })
        });
    // modal end

    // buyInfo start
        $('.js-buy-city-select').on('change', function (e) {
            setGetParameter('city', $(this).val());
        });
        function setGetParameter(paramName, paramValue)
        {
            var url = window.location.href;
            var hash = location.hash;
            url = url.replace(hash, '');
            if (url.indexOf(paramName + "=") >= 0)
            {
                var prefix = url.substring(0, url.indexOf(paramName + "="));
                var suffix = url.substring(url.indexOf(paramName + "="));
                suffix = suffix.substring(suffix.indexOf("=") + 1);
                suffix = (suffix.indexOf("&") >= 0) ? suffix.substring(suffix.indexOf("&")) : "";
                url = prefix + paramName + "=" + paramValue + suffix;
            }
            else
            {
            if (url.indexOf("?") < 0)
                url += "?" + paramName + "=" + paramValue;
            else
                url += "&" + paramName + "=" + paramValue;
            }
            window.location.href = url + hash;
        }
        $(".js-buyinfo-scrollbar").mCustomScrollbar();
        $('.js-buyinfo-address-btn').click(function(){
            var address = $(this).find('.js-buyinfo-address').text();
            $('.js-buyinfo-address-btn').removeClass('active');
            $(this).addClass('active');

            ymaps.geocode($('.js-buy-city-select option:selected').text()+' '+address).then(
                function (res) {
                    myMap.setCenter(res.geoObjects.get(0).geometry.getCoordinates());
                    myMap.setZoom(16);
                }
            );
        });
    // buyInfo end

    // catalog add cart start
        $(document).on('click', '.js-catalog-add-cart-btn', function(event){
            event.preventDefault();
            var url = $(this).data('url');
            $.ajax({
                url : url,
                type: 'GET',
                dataType: 'HTML',
                success : function(data){
                    $('.js-modal-box-catalog-cart-add').fadeIn('slow');
                    $('.js-modal-box-block-product').html(data);
                }
            })
        })
    // catalog add cart end

    // cart promocode start
        $('.js-cart-promocode').click(function(event){
            var code  = $('.js-cart-promocode-input').val();
            window.location.href = addParameterToURL('code='+code);
            // event.preventDefault();
            // var $this = $(this);
            // var url   = $(this).data('url');
            // var code  = $('.js-cart-promocode-input').val();
            //
            // if (code != '') {
            //     $('.js-order-form-promocode').val(code);
            //     $.ajax({
            //         url : url,
            //         type: 'post',
            //         data: {code:$('.js-cart-promocode-input').val()},
            //         dataType: 'json',
            //         success : function(data){
            //             if (data.success) {
            //                 var currentPrice  = $('.js-cart-summary-value').text();
            //                 // var deliveryPrice = parseInt($('.cart-summary--delivery-price .cart-summary--value').text());
            //                 var deliveryPrice = 0;
            //                 var newPrice;
            //
            //                 var bonusPrice = 0;
            //                 if ($('.js-cart-bonus-input').length) {
            //                     bonusPrice = Math.max(bonusPrice, $('.js-cart-bonus-input').val());
            //                 }
            //
            //                 if (data.type == 'percent') {
            //                     newPrice = Math.max((parseInt(currentPrice, 10) - parseInt(currentPrice, 10)*data.value/100 + deliveryPrice - bonusPrice), 0) + ' руб.';
            //                 }
            //                 else {
            //                     newPrice = Math.max((parseInt(currentPrice, 10) - data.value + deliveryPrice - bonusPrice), 0) + ' руб.';
            //                 }
            //
            //                 $('.cart-summary--total .cart-summary--value').each(function(){
            //                     $(this).text(newPrice);
            //                 });
            //                 $('.js-cart-promocode-input').attr("disabled", true);
            //                 $this.hide();
            //
            //                 $('.js-cart-summary-value').addClass('old-price-disabled');
            //                 $('.js-cart-promocode-price').removeClass('hidden').text(newPrice);
            //             }
            //         }
            //     })
            // }
        });
    // cart promocode end

    // certificates start
        $('.js-certificates-box-btn').click(function(){
            var images = $(this).data('images').split(",");
            var certificatesSlider = $('.js-certificates-carousel');
            certificatesSlider.trigger('destroy.owl.carousel');
            certificatesSlider.html('').removeClass('owl-loaded');


            for (var i = 0; i < images.length; i++) {
                $('.js-certificates-carousel').append('<div class="item"><img src="'+$.trim(images[i])+'"></div>');
            }
            certificatesSlider.owlCarousel({
                loop:false,
                margin:0,
                nav:true,
                items:1,
                dots: true,
                navText: ['<span class="slider-prev-btn"><img src="/images/angel_bracket_left_black.svg"></span>','<span class="slider-next-btn"><img src="/images/angel_bracket_right_black.svg"></span>']
            })
            // certificatesSlider.on('initialized.owl.carousel', function(event){
                $('.js-modal-box-certificates').fadeIn('slow');
            // });

        });
    // certificates end

    // registration start
        if ($('.js-modal-box-registration-success').length) {
            $('.js-modal-box-registration-success').fadeIn('slow');
        }
    // registration end

    // admin product taste start
        // if ($('#product_tastes').length) {
        //     $('#product_tastes > fieldset').each(function(index){
        //         $(this).find('div').first().append('<div class="form-group"><button type="button" class="js-remove-taste-product">Удалить</button></div>')
        //     });
        // }
        //
        // $(document).on('click', '.add_tag_link', function(event){
        //     var interval = setInterval(function(){
        //         if ($('.js-new-added-taste').length) {
        //             $('#product_tastes > li.js-new-added-taste').each(function(index){
        //                 $(this).removeClass('js-new-added-taste');
        //                 var box = $(this).find('fieldset');
        //                 if (!box.find('.js-remove-taste-product').length) {
        //                     box.find('div').first().append('<div class="form-group"><button type="button" class="js-remove-taste-product">Удалить</button></div>')
        //                 }
        //             });
        //             clearInterval(interval);
        //         }
        //
        //     }, 100);
        // })
        // $(document).on('click', '.js-remove-taste-product', function(event){
        //     event.preventDefault();
        //     var box = $(this).closest('fieldset');
        //     var id  = box.find('div').first().attr('id');
        //     id = id.substring(id.lastIndexOf('_') + 1);
        //     id = $('#product_tastes_'+id+'_id').val();
        //     if (id) {
        //         $.ajax({
        //             url : '/admin/taste/deletebyid/'+id,
        //             type: 'GET',
        //             dataType: 'json',
        //             success : function(data){
        //                 if (data.success) {
        //                     box.remove();
        //                 }
        //             }
        //         })
        //     }
        //     else {
        //         box.closest('li').remove();
        //     }
        // })
    // admin product taste end

    // save column start
        $('.js-edit-row-btn-cancel').click(function(){
            var box   = $(this).closest('.js-edit-row');
            var value = $(this).data('value');
            box.find('.js-edit-row-input').val(value);
        });
        $('.js-edit-row-btn-save').click(function(){
            var box   = $(this).closest('.js-edit-row');
            var value = box.find('.js-edit-row-input').val();
            var id    = $(this).data('id');
            var field = $(this).data('field');
            var url   = $(this).data('url');
            $.ajax({
                url : url,
                type: 'POST',
                dataType: 'json',
                data: {id:id, value:value, field:field},
                success : function(data){
                    if (data.success) {
                        // console.log('save');
                    }
                }
            })
        });
    // save column end

    // delete taste start
        $('.js-delete-taste-btn').click(function(e){
            var orders = $(this).data('orders');
            if (orders.replace(/\s/g, "") != '') {
                alert(orders);
            }
        });
    // delete taste end

    // repeat order start
        $('.js-repeat-order-btn').click(function(){
            var url  = $(this).data('url');
            var text = $(this).data('text');
            $.ajax({
                url : url,
                type: 'POST',
                dataType: 'json',
                success : function(data){
                    message = '';
                    if (data) {
                        for (var i = 0; i < data.length; i++) {
                            if (data[i].message) {
                                message += data[i].message+'\n';
                            }
                        }
                        if (message != '') {
                            alert(message);
                        }
                        else {
                            alert(text);
                        }
                    }
                }
            })
        });
    // repeat order end


    // change password start
        $('.js-profile-change-password-form').submit(function(){
            if ($('.js-profile-change-password-new').val() != $('.js-profile-change-password-repeat').val()) {
                alert('Пароли не совпадают');

                return false;
            }
        });
    //change password end

    // review gift file input control start
        $('.js-review-gift-file').change(function() {
            if (this.files[0].size/1024/1024 > 2) {
                alert('Размер файла должен быть не более 2Мб');
                $('.js-review-gift-submit').attr('disabled', true);
            }
            else {
                $('.js-review-gift-submit').attr('disabled', false);
            }
        });
    // review gift file input control end

    // home page promo desktop/mobile background switcher start
        var doit;
        window.onresize = function(){
            clearTimeout(doit);
            doit = setTimeout(resizedw, 100);
        };
        setPromoBackground();
    // home page promo desktop/mobile background switcher end

    // top carousel start
        initTopCarousel();
    // top carousel end

    // cart delivery type set start
        $('.js-delivery-type').change(function(){
            $('.js-deliveryType').val($(this).val());
        });
    // cart delivery type set end

    // user profile edit start
        $('.js-profile-edit-btn').click(function(){
            var box = $('.js-profile-edit-box');
            if ($(this).hasClass('active')) {
                box.slideUp('slow');
                $(this).removeClass('active');
            }
            else {
                box.slideDown('slow');
                $(this).addClass('active');
            }
        })
    // user profile edit end

    // product reminder start
        $('.js-product-smart-btn').click(function(){
            var btn = $(this).find('.js-product-smart-btn-reminder');
            if (btn.length) {
                $.ajax({
                    url : btn.data('url'),
                    type: 'POST',
                    dataType: 'json',
                    success : function(data){
                        showModalInfo(data.message)
                    }
                })
            }
        });
    // product reminder end
});

function initTopCarousel() {
    if ($(window).width() >= 1024) {
        $('.js-tops-carousel').owlCarousel({
            loop:true,
            margin:10,
            nav:true,
            dots: false,
            items:4,
            autoplay:false,
            navText: ['<div class="category-scroll-button category-scroll-button-left tops-slider-left"><img src="/images/gallery_arrow_left.svg"></div>','<div class="category-scroll-button category-scroll-button-right tops-slider-right"><img src="/images/gallery_arrow_right.svg"></div>'],
        });
    }
    else {
        $('.js-tops-carousel').owlCarousel('destroy');
        $('.js-tops-carousel').addClass('tops-slider-mobile');
    }
}

function setPromoBackground() {
    initTopCarousel();
    if ($(window).width() <= 575) {
        $('.js-slider-content-image').each(function(){
            var mobileBackground = $(this).data('backgroundmobile');
            if (mobileBackground) {
                $(this).css({
                    'background-image' : 'url("'+mobileBackground+'")'
                })
            }
        });
    }
    else {
        $('.js-slider-content-image').each(function(){
            var desktopBackground = $(this).data('backgrounddesktop');
            if (desktopBackground) {
                $(this).css({
                    'background-image' : 'url("'+desktopBackground+'")'
                })
            }
        });
    }
}

function resizedw(){
    setPromoBackground();
}

function addParameterToURL(param){
    _url = location.href;
    _url += (_url.split('?')[1] ? '&':'?') + param;
    return _url;
}

// tooltip start
(function($){

    var $div = $('<div/>').addClass('custom-tooltip').appendTo('body');

    $('.js-tooltip')
        .hover(function(e){
            var width = $div.width();
            var space = 15;

            if (e.pageX+width+3*space > $( document ).width()) {
                $div.css({
                    top: e.pageY + space + 'px',
                    right: space,
                    left: 'auto'
                });
            }
            else {
                $div.css({
                    top: e.pageY + space + 'px',
                    left: e.pageX + space + 'px',
                    right: 'auto'
                });
            }
            $div.show().html($(this).data('title'));
        }, function(){
            $div.hide();
        });

    })(jQuery);
// tooltip end
